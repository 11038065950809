import * as React from "react";
import { LayoutBoxMinimal } from "../atoms/LayoutBox";

export const TextBanner = ({ title }) => (
  <div className="hero is-small has-background-light-blue">
    <div className="hero-body pl-6 pr-6">
      <LayoutBoxMinimal>
        <h1
          className="has-text-weight-bold is-size-1 is-clipped"
          style={{ textOverflow: "ellipsis" }}
        >
          {title}
        </h1>
      </LayoutBoxMinimal>
    </div>
  </div>
);
