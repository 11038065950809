import React from "react";
import { LayoutBox } from "../../components/atoms/LayoutBox";
import Layout from "../../components/Layout";
import { TextBanner } from "../../components/molecules/TextBanner";
import { Link } from "gatsby";

const TermsAndConditions = () => {
  return (
    <Layout title="Terms and conditions">
      <TextBanner title="Terms &amp; Conditions" />
      <LayoutBox>
        <p>
          By downloading or using the neumind app, these terms will apply to
          you. You may not copy, or modify the app, any part of the app, or our
          trademarks in any way. You may not attempt to extract the source code
          of the app, and you may not try to translate the app into other
          languages, or make derivative versions. The app itself, and all the
          trade marks, copyright, database rights and other intellectual
          property rights related to it, still belong to neumind, ltd.
        </p>
        <br />
        <p>
          neumind, ltd is committed to ensuring that the app is as useful and
          efficient as possible. For that reason, we reserve the right to make
          changes to the app or to charge for its services, at any time and for
          any reason.
        </p>
        <br />

        <p>
          Our app stores and processes personal data provided to us, in order to
          provide the service.
        </p>
        <br />

        <div>
          <p>
            The app uses third party services that declare their own Terms and
            Conditions.
          </p>
          <br />

          <strong>
            Non-exhaustive list of terms and conditions of third party service
            providers used by the app
          </strong>
          <ul>
            <li>
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Play Services
              </a>
            </li>
            <li>
              <a
                href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Apple EULA
              </a>
            </li>
            <li>
              <a
                href="https://firebase.google.com/terms/analytics"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics for Firebase
              </a>
            </li>
            <li>
              <a
                href="https://expo.io/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Expo
              </a>
            </li>
          </ul>
        </div>
        <br />

        <p>
          In order to use the app as expected you must have an active internet
          connection.
        </p>
        <br />

        <p>
          With respect to neumind, ltd’s responsibility for your use of the app,
          when you’re using the app, it’s important to bear in mind that
          although we endeavour to ensure that it is updated and correct at all
          times, we do rely on third parties to provide information to us so
          that we can make it available to you. neumind, ltd accepts no
          liability for any loss, direct or indirect, you experience as a result
          of relying wholly on this functionality of the app.
        </p>
        <br />

        <p>
          We frequently issue updates for the app. The app is currently
          available on Android &amp; iOS – the requirements for both systems
          (and for any additional systems we decide to extend the availability
          of the app to) may change, and you’ll need to download the updates if
          you want to keep using the app. neumind, ltd does not promise that it
          will always update the app so that it is relevant to you and/or works
          with the Android &amp; iOS version that you have installed on your
          device. However, you promise to always accept updates to the
          application when offered to you. We may also wish to stop providing
          the app, and may terminate use of it at any time without giving notice
          of termination to you. Unless we tell you otherwise, upon any
          termination, (a) the rights and licenses granted to you in these terms
          will end; (b) you must stop using the app, and (if needed) delete it
          from your device.
        </p>
        <br />

        <p>App Store® and Apple® are trademarks of Apple Inc.</p>

        <p>
          Google Play and the Google Play logo are trademarks of Google LLC.
        </p>
        <br />

        <p>
          <strong>Changes to This Terms and Conditions</strong>
        </p>
        <br />

        <p>
          These terms and conditions may be changed from time to time in
          response to legal, technical or business developments. We will take
          appropriate measures to inform you when we update our privacy notice.
          We will obtain your consent to any material privacy notice changes if
          and where this is required by applicable Data Protection Laws.
        </p>
        <p>This version was last updated on 27 April 2023.</p>
        <br />

        <p>
          <strong>Contact Us</strong>
        </p>
        <p>
          If you have any questions or suggestions about our Terms and
          Conditions, contact us at team@neumind.co.uk or via our{" "}
          <Link to="/contact">contact form</Link>.
        </p>
      </LayoutBox>
    </Layout>
  );
};

export default TermsAndConditions;
